import React from "react";
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import "./App.css";
import Home from './components/Home'
import Resume from './components/Resume'
import Portfolio from './components/Portfolio'
import Contacts from './components/Contacts'

function App(){
    return (
        <BrowserRouter>
        <CssBaseline/>
        <Switch>
            <Route exact path = "/"  component={Home} />
            <Route path = "/resume" component={Resume} />
            <Route path = "/portfolio" component={Portfolio} />
            <Route path = "/contacts" component={Contacts} />
        </Switch>
         </BrowserRouter>
    )
}

export default App;