import React from 'react'
import {makeStyles , withStyles} from "@material-ui/core/styles"
import {TextField, Typography , Button , Grid, Box } from "@material-ui/core"
import SendIcon from "@material-ui/icons/Send"
import Navbar from "./Navbar"

const useStyles = makeStyles(theme => ({
    form : {
        top: "50%",
        left : "50%",
        transform : "translate(-50%, -50%)",
        position: "absolute"
    },
    button  : {
        marginTop : "1rem",
        color : "#32e0c4",
        borderColor : "grey",
        fontSize: "20px"
    }
}))

const InputField = withStyles ({
    root : {
        "& label.Mui-focused" : {
            color: "#32e0c4",
            background: "none",
            fontSize: "20px"
        },
        "& label" : {
            color: "dark",
        },
        "& .MuiOutlinedInput-root" : {
            "& fieldset" : {
                borderColor : "dark",
            },
            "&:hover fieldset" : {
                borderColor : "dark",
            }, 
            "&.Mui-focused fieldset" : {
                borderColor : "#213e3b",
            },
        },
    }, 
})(TextField);

const  Contacts = () =>  {
    const classes = useStyles();
    return (
        <Box component="div">
        <Navbar />
         <Grid container justify="center">
             <Box component="form" className={classes.form}>
               <Typography variant="h5" 
                 style = {{
                   color: "#213e3b", 
                   textAlign:"center",
                   fontSize: "29px",
                   textTransform : "uppercase",
                    fontFamily: 'Quicksand'
                }}>
                 Give Me Your Work And <br/> I'll Show Mine <span><i class="fa fa-cog fa-spin"></i></span> 
               </Typography>
               <InputField 
                  fullWidth={true} 
                  label="Name" 
                  variant="outlined"  
                  margin="dense" 
                  size="medium" 
                  inputProps={{style:  {color: "#fff"} }} 
                />
                <br/>
               <InputField 
                    fullWidth={true} 
                    label="Email" 
                    variant="outlined"  
                    margin="dense" 
                    size="medium" 
                    inputProps={{style:  {color: "#fff"}}}
                />
                <br/>
               <InputField 
                    fullWidth={true} 
                    label="Company Name" 
                    variant="outlined" 
                    margin="dense" 
                    size="medium" 
                    inputProps={{style:  {color: "#fff"}}}
                />

               <br/>

               <Button className={classes.button} variant="outlined" fullWidth={true} endIcon={<SendIcon/>}>
                   Contact me 
               </Button>
             </Box>
         </Grid>
        </Box>
    )
}

export default Contacts
