import React from 'react'
import {makeStyles} from "@material-ui/styles"
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core"
import Facebook from "@material-ui/icons/Facebook"
import Twitter from "@material-ui/icons/Twitter"
import Instagram from "@material-ui/icons/Instagram"
import Youtube from "@material-ui/icons/YouTube"


const useStyles = makeStyles ({
  root : {
    "& .MuiBottomNavigationAction-root": {
        minWidth : "0",
        maxWidth: "500",
    },
    "& .MuiSvgIcon-root": {
        fill : "tan",
        "&:hover": {
            fill: "tomato",
            fontSize: "1.8rem"
        }
    },
    margin : "1px 3px 1px 1px"
  },
 })

const Footer = () => {
    const classes = useStyles();
    return (
        <BottomNavigation width="auto" style={{background: "#222"}}>
            <BottomNavigationAction
            className={classes.root}
                style={{padding: 0}}
              
                icon ={ <a href="https://www.facebook.com/PukaRnn"><Facebook/></a> }
            />
            <BottomNavigationAction
            className={classes.root}
                style={{padding: 0}}
                icon ={ <a href="https://twitter.com/___pukar"><Twitter/></a>}
            />
            <BottomNavigationAction
            className={classes.root}
                style={{padding: 0}}
                icon ={ <a href="https://www.instagram.com/____pukar/"><Instagram/></a> }
            />
            <BottomNavigationAction
            className={classes.root}
                style={{padding: 0}}
                icon ={<a href="https://www.youtube.com/channel/UCWdSoL5o2iAdhxSLQZFHeUQ"><Youtube/></a> }
            />
        </BottomNavigation>
    )
}

export default Footer
