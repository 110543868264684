import React, {useState} from 'react';
import avatar from '../images/avatar.png'
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MobilRightMenuSlider from "@material-ui/core/Drawer"
import Footer from './Footer'
import {
    AppBar, 
    Toolbar,
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemText,
    Avatar,
    Divider,
    List,
    Typography,
    Box
} from "@material-ui/core"
import {
    ArrowBack,
    AssignmentInd,
    Home,
    Apps,
    ContactMail    
} from "@material-ui/icons"

// CSS styles
const useStyles = makeStyles(theme => ({
    menuSliderContainer :{
        width: "auto",
        background: "#0d5445",
        height: "100%"
    },
    avatar : {
        display: "block",
        margin: "0.5rem auto",
        width: theme.spacing(13),
        height: theme.spacing(13)
    }, 
    listItem : {
        color : "tan",
    },
    appbar : {
        opacity: 0.7,
        position: "static"
    },
    arrow :
     {
         fontSize : "30px",
        "&:hover": {
            fill: "#14ffec",
        }
    },
    h1 : {
        fontSize: "16px",
        fontFamily: "Quicksand"
    }
}))

const menuItems  = [
    {
        listIcon : <Home />,
        listText : "Home",
        listPath : "/"
    },
    {
        listIcon : <AssignmentInd />,
        listText : "Resume",
        listPath : "/resume"
    },
    {
        listIcon : <Apps />,
        listText : "Portfolio",
        listPath : "/portfolio"
    },
    {
        listIcon : <ContactMail />,
        listText : "Contacts",
        listPath : "/contacts"
    }
]

const  Navbar = () =>  {
    const [state, setState] = useState({
        right : false
    })

    const toggleSlider = (slider, open) => () => {
        setState({...state, [slider]: open});
    };
    const classes = useStyles();

    const sideList = slider => (
        <Box className={classes.menuSliderContainer} component="div" onClick= {toggleSlider(slider, false)} >
          <Avatar className={classes.avatar} src={avatar} alt="Pukar Loading..." />
          <Divider />
          <List>
          {menuItems.map((lsItem, key) => (
              <ListItem button key={key} component={Link} to={lsItem.listPath}>
                  <ListItemIcon className={classes.listItem } >{lsItem.listIcon}</ListItemIcon>
                  <ListItemText className={classes.listItem }  primary={lsItem.listText} />
              </ListItem>
          ))}
          </List>
        </Box>
    )
    return (
        <>
        <Box component="nav">
            <AppBar className={classes.appbar}  style={{background: "#222"}}>
                <Toolbar>
                <IconButton onClick={toggleSlider("right", true)}>  
                    <ArrowBack style={{color: "gray"}} className = {classes.arrow}/>
                </IconButton>
                <Typography variant="h5" style={{color: "#fff"}} className={classes.h1}>
                    PORTFOLIO
                </Typography>
                <MobilRightMenuSlider 
                anchor="right"
                 open= {state.right}
                 onClose={toggleSlider("right", false)}
                 >
            
                   {sideList("right")}
                   <Footer />
                </MobilRightMenuSlider>
                </Toolbar>
            </AppBar>
        </Box>
    </>
    )
}

export default Navbar
